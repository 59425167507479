// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT LINK
import { Link } from 'react-router-dom'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// WE MOTIVASI
const WeMotivasi = () => { // BUKA KURUNG WE MOTIVASI
    // MEMBUAT VARIABEL WE MOTIVASI
    const [dataResponse1, setDataResponse1] = useState([])
    const [dataResponse2, setDataResponse2] = useState([])
    const [dataResponse3, setDataResponse3] = useState([])
    const [dataResponse4, setDataResponse4] = useState([])
    const [dataResponse5, setDataResponse5] = useState([])
    const [dataResponse6, setDataResponse6] = useState([])
    const [display, setDisplay] = useState('show')
    const [displayPendidikan, setDisplayPendidikan] = useState('')
    const [displayPekerjaan, setDisplayPekerjaan] = useState('')
    const [displayKehidupan, setDisplayKehidupan] = useState('')
    const [displayPercintaan, setDisplayPercintaan] = useState('')
    const [displayEmosional, setDisplayEmosional] = useState('')
    // USEEFFECT
    useEffect(()=>{
        selectWeMotivasi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // PROSES MENAMPILKAN DATA WE MOTIVASI
    const selectWeMotivasi = async() =>{
        try{
            const response1 = await axios.get('https://server13.webew.id/selectAllWeMotivasiBE')
            const response2 = await axios.get('https://server13.webew.id/selectWeMotivasiByJenisMotivasiBE/MotivasiPendidikan')
            const response3 = await axios.get('https://server13.webew.id/selectWeMotivasiByJenisMotivasiBE/MotivasiPekerjaan')
            const response4 = await axios.get('https://server13.webew.id/selectWeMotivasiByJenisMotivasiBE/MotivasiKehidupan')
            const response5 = await axios.get('https://server13.webew.id/selectWeMotivasiByJenisMotivasiBE/MotivasiPercintaan')
            const response6 = await axios.get('https://server13.webew.id/selectWeMotivasiByJenisMotivasiBE/MotivasiEmosional')
            setDataResponse1(response1.data)
            setDataResponse2(response2.data)
            setDataResponse3(response3.data)
            setDataResponse4(response4.data)
            setDataResponse5(response5.data)
            setDataResponse6(response6.data)
        }catch(error){
            console.log(error)
        }
    }
    // BUTTON MENAMPILKAN SEMUA MOTIVASI
    const btnMotivasi = () => {
        setDisplay('show')
        setDisplayPendidikan('')
        setDisplayPekerjaan('')
        setDisplayKehidupan('')
        setDisplayPercintaan('')
        setDisplayEmosional('')
    }
    // BUTTON MENAMPILKAN MOTIVASI PENDIDIKAN
    const btnMotivasiPendidikan = () => {
        setDisplayPendidikan('show')
        setDisplay('')
        setDisplayPekerjaan('')
        setDisplayKehidupan('')
        setDisplayPercintaan('')
        setDisplayEmosional('')
    }
    // BUTTON MENAMPILKAN MOTIVASI PEKERJAAN
    const btnMotivasiPekerjaan = () => {
        setDisplayPekerjaan('show')
        setDisplay('')
        setDisplayPendidikan('')
        setDisplayKehidupan('')
        setDisplayPercintaan('')
        setDisplayEmosional('')
    }
    // BUTTON MENAMPILKAN MOTIVASI KEHIDUPAN
    const btnMotivasiKehidupan = () => {
        setDisplayKehidupan('show')
        setDisplay('')
        setDisplayPendidikan('')
        setDisplayPekerjaan('')
        setDisplayPercintaan('')
        setDisplayEmosional('')
    }
    // BUTTON MENAMPILKAN MOTIVASI PERCINTAAN
    const btnMotivasiPercintaan = () => {
        setDisplayPercintaan('show')
        setDisplay('')
        setDisplayPendidikan('')
        setDisplayPekerjaan('')
        setDisplayKehidupan('')
        setDisplayEmosional('')
    }
    // BUTTON MENAMPILKAN MOTIVASI EMOSIONAL
    const btnMotivasiEmosional = () => {
        setDisplayEmosional('show')
        setDisplay('')
        setDisplayPendidikan('')
        setDisplayPekerjaan('')
        setDisplayKehidupan('')
        setDisplayPercintaan('')
    }
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>We Motivasi</h2>
                                <p className='text-webew-home fw-normal'>Yuk buat kata-kata motivasi disini, tinggal buat kata-kata motivasi lalu di upload deh. Selain itu kamu juga bisa melihat motivasi lainnya.</p>
                                <a href='/' className='btn btn-webew-product' aria-label='Kembali webew.id'><i class="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/4167279_18774.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Vectorjuice on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung' style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* BOX MOTIVASI */}
                <section>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-4 order-0 order-lg-1 mb-4'>
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        <div className="box p-4">
                                            <h3 className='text-center fw-bold'>Buat Kata Motivasi</h3>
                                            <p className='text-webew-home fw-normal mb-3'>Silahkan Login ke WeMotivasi untuk membuat kata-kata motivasi</p>
                                            <a href={'https://webew.id/login/menu-we-motivasi'} className='btn btn-webew-product' aria-label='Produk webew.id'>Login</a>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="box">
                                            <img src="https://webew.id/img/banner.png" alt="banner webew.id" className='mx-auto d-block' style={{width:"100%",borderRadius:"20px"}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-8 order-1 order-lg-0'>
                                <div className='box p-4' style={{width: '100%'}}>
                                    <div className='dropdown-center'>
                                        <button className='btn btn-webew-product' type='button' data-bs-toggle='dropdown' aria-expanded='false' style={{padding:'7px 0 7px 0'}}><i className='bi bi-filter' style={{color:'white'}}></i> Kategori Jenis Motivasi</button>
                                        <ul className='dropdown-menu text-center mt-3'>
                                            <li><button onClick={btnMotivasi} className='dropdown-item' aria-label='Motivasi' style={{fontSize:'13pt'}}>Tampilkan Semua Motivasi</button></li>
                                            <li><button onClick={btnMotivasiPendidikan} className='dropdown-item' aria-label='Motivasi' style={{fontSize:'13pt'}}>Tampilkan Motivasi Pendidikan</button></li>
                                            <li><button onClick={btnMotivasiPekerjaan} className='dropdown-item' aria-label='Motivasi' style={{fontSize:'13pt'}}>Tampilkan Motivasi Pekerjaan</button></li>
                                            <li><button onClick={btnMotivasiKehidupan} className='dropdown-item' aria-label='Motivasi' style={{fontSize:'13pt'}}>Tampilkan Motivasi Kehidupan</button></li>
                                            <li><button onClick={btnMotivasiPercintaan} className='dropdown-item' aria-label='Motivasi' style={{fontSize:'13pt'}}>Tampilkan Motivasi Percintaan</button></li>
                                            <li><button onClick={btnMotivasiEmosional} className='dropdown-item' aria-label='Motivasi' style={{fontSize:'13pt'}}>Tampilkan Motivasi Emosional</button></li>
                                        </ul>
                                    </div>
                                    <table className='mt-5'>
                                        { display === 'show'
                                            ?<tbody>
                                                {dataResponse1.map((user,index)=>(
                                                    <tr key={dataResponse1.id}>
                                                        <td style={{width:'100%'}}>
                                                            <div className='box p-3 mb-4'>
                                                                { user.urlFoto === null
                                                                    ? <img src='https://webew.id/img/default.jpg' className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                    : <img src={user.urlFoto} className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                }
                                                                <p style={{backgroundColor:'white',textAlign:'center',borderRadius:'20px',padding:'5px 7px 5px 7px',fontSize:'13px',fontWeight:'700'}}>{user.name}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.kata}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'700'}}>#{user.jenisMotivasi}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.createdAt.substring(0,10)}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            :''
                                        }
                                        { displayPendidikan === 'show'
                                            ?<tbody>
                                                {dataResponse2.map((user,index)=>(
                                                    <tr key={dataResponse2.id}>
                                                        <td style={{width:'100%'}}>
                                                            <div className='box p-3 mb-4'>
                                                                { user.urlFoto === null
                                                                    ? <img src='/img/profil/default.jpg' className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                    : <img src={user.urlFoto} className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                }
                                                                <p style={{backgroundColor:'white',textAlign:'center',borderRadius:'20px',padding:'5px 7px 5px 7px',fontSize:'13px',fontWeight:'700'}}>{user.name}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.kata}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'700'}}>#{user.jenisMotivasi}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.createdAt.substring(0,10)}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            :''
                                        }
                                        { displayPekerjaan === 'show'
                                            ?<tbody>
                                                {dataResponse3.map((user,index)=>(
                                                    <tr key={dataResponse3.id}>
                                                        <td style={{width:'100%'}}>
                                                            <div className='box p-3 mb-4'>
                                                                { user.urlFoto === null
                                                                    ? <img src='/img/profil/default.jpg' className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                    : <img src={user.urlFoto} className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                }
                                                                <p style={{backgroundColor:'white',textAlign:'center',borderRadius:'20px',padding:'5px 7px 5px 7px',fontSize:'13px',fontWeight:'700'}}>{user.name}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.kata}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'700'}}>#{user.jenisMotivasi}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.createdAt.substring(0,10)}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            :''
                                        }
                                        { displayKehidupan === 'show'
                                            ?<tbody>
                                                {dataResponse4.map((user,index)=>(
                                                    <tr key={dataResponse4.id}>
                                                        <td style={{width:'100%'}}>
                                                            <div className='box p-3 mb-4'>
                                                                { user.urlFoto === null
                                                                    ? <img src='/img/profil/default.jpg' className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                    : <img src={user.urlFoto} className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                }
                                                                <p style={{backgroundColor:'white',textAlign:'center',borderRadius:'20px',padding:'5px 7px 5px 7px',fontSize:'13px',fontWeight:'700'}}>{user.name}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.kata}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'700'}}>#{user.jenisMotivasi}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.createdAt.substring(0,10)}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            :''
                                        }
                                        { displayPercintaan === 'show'
                                            ?<tbody>
                                                {dataResponse5.map((user,index)=>(
                                                    <tr key={dataResponse5.id}>
                                                        <td style={{width:'100%'}}>
                                                            <div className='box p-3 mb-4'>
                                                                { user.urlFoto === null
                                                                    ? <img src='/img/profil/default.jpg' className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                    : <img src={user.urlFoto} className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                }
                                                                <p style={{backgroundColor:'white',textAlign:'center',borderRadius:'20px',padding:'5px 7px 5px 7px',fontSize:'13px',fontWeight:'700'}}>{user.name}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.kata}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'700'}}>#{user.jenisMotivasi}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.createdAt.substring(0,10)}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            :''
                                        }
                                        { displayEmosional === 'show'
                                            ?<tbody>
                                                {dataResponse6.map((user,index)=>(
                                                    <tr key={dataResponse6.id}>
                                                        <td style={{width:'100%'}}>
                                                            <div className='box p-3 mb-4'>
                                                                { user.urlFoto === null
                                                                    ? <img src='/img/profil/default.jpg' className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                    : <img src={user.urlFoto} className='mx-auto d-block' alt='foto' style={{minWidth:'30px',maxWidth:'30px',borderRadius:'30px'}}/>
                                                                }
                                                                <p style={{backgroundColor:'white',textAlign:'center',borderRadius:'20px',padding:'5px 7px 5px 7px',fontSize:'13px',fontWeight:'700'}}>{user.name}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.kata}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'700'}}>#{user.jenisMotivasi}</p>
                                                                <p style={{backgroundColor:'white',textAlign:'left',borderRadius:'20px',padding:'0px 10px 0px 10px',fontSize:'13px',fontWeight:'600'}}>{user.createdAt.substring(0,10)}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            :''
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>   
                </section>
            {/* TUTUP BOX MOTIVASI */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/marketing-group-working-business-solution-flat-icon_4167279.htm#from_view=detail_alsolike' aria-label='Link mengarah ke pembuat image yaitu katemangostar Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/4167279_18774.webp' className='mx-auto d-block pt-4' style={{width: '100%',minHeight:'90px'}} alt='by katemangostar on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>katemangostar on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBARBOT */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE MOTIVASI
// EKSPOR
export default WeMotivasi