// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useParams, useNavigate } from "react-router-dom"
// MENU NAVBAR BOTTOM
const MenuNavbarBot = () => { // KURUNG BUKA MENU NAVBAR BOTTOM
    // PARAMS
    const {panggilanParams} = useParams()
    // MEMBUAT VARIABEL URL FOTO
    const [urlFoto, setUrlFoto] = useState("")
    // MEMBUAT VARIABEL PANGGILAN
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        refreshToken()
        selectWeUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        } catch (error) {
            if(error.response){
                navigate("/login/dashboard", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // LOGOUT
    const Logout = async() =>{
        try {
            await axios.delete('https://server13.webew.id/logoutBE')
            navigate("/login/dashboard", { replace: true })
        } catch (error) {
            console.log(error)
        }
    }
    // MENAMPILKAN WE USERS BY PARAMS PANGGILAN
    const selectWeUsers = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeUsersBE/${panggilanParams}`)
            setUrlFoto(response.data.urlFoto)
        }catch(error){
            if(error.response){
                console.log(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* NAVBAR BOTTOM */}
            <div className="navbot">
                <div className="container fixed-bottom" style={{width:"100%",height:"auto",background:"white", padding:"5px 0 10px 0",borderTopLeftRadius:"30px",borderTopRightRadius:"30px",boxShadow: "0 -15px 18px 5px rgba(211, 215, 241, 0.507)"}}>
                    <div className="row justify-content-center">
                        <div className="col-2 d-flex justify-content-center"><a href={`/dashboard/${panggilan}`} className="btn btn-webew-product logout-btn" style={{width:"auto",padding:"1px 8px",marginTop:"3px", position: "relative"}} aria-label="Produk webew.id"><i className="bi bi-house-up" style={{fontSize:"20pt",color:"white"}}></i><span className="logout-text">Dashboard</span></a></div>
                        <div className="col-2 d-flex justify-content-center">
                            <div class="dropdown-center">
                                <button class="btn btn-webew-product logout-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width:"auto",padding:"1px 8px",marginTop:"3px", position: "relative"}}><i class="bi bi-grid" style={{fontSize:"20pt",color:"white"}}></i><span className="logout-text">Menu Aplikasi</span></button>
                                <ul class="dropdown-menu mb-3">
                                    <li><a href={`/menu-we-aqiqah/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Aqiqah" style={{fontSize:"13pt"}}><i class="bi bi-grid"></i> Menu We Aqiqah</a></li>
                                    <li><a href={`/menu-we-cv/${panggilan}`} className="dropdown-item fw-normal" aria-label="We CV" style={{fontSize:"13pt"}}><i class="bi bi-grid"></i> Menu We CV</a></li>
                                    <li><a href={`/menu-we-khodam/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Khodam" style={{fontSize:"13pt"}}><i class="bi bi-grid"></i> Menu We Khodam</a></li>
                                    <li><a href={`/menu-we-kompres/${panggilan}`} className="dropdown-item fw-normal" aria-label="We kompres" style={{fontSize:"13pt"}}><i class="bi bi-grid"></i> Menu We Kompres</a></li>
                                    <li><a href={`/menu-we-konversi/${panggilan}`} className="dropdown-item fw-normal" aria-label="We kompres" style={{fontSize:"13pt"}}><i class="bi bi-grid"></i> Menu We Konversi</a></li>
                                    <li><a href={`/menu-we-motivasi/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Motivasi" style={{fontSize:"13pt"}}><i class="bi bi-grid"></i> Menu We Motivasi</a></li>
                                    <li><a href={`/menu-we-nabung/${panggilan}/${panggilan}`} className="dropdown-item fw-normal" aria-label="We nabung" style={{fontSize:"13pt"}}><i class="bi bi-grid"></i> Menu We Nabung</a></li>
                                    <li><a href={`/menu-we-pernikahan/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Pernikahan" style={{fontSize:"13pt"}}><i class="bi bi-grid"></i> Menu We Pernikahan</a></li>
                                    <li><a href={`/menu-we-ultah/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Ultah" style={{fontSize:"13pt"}}><i class="bi bi-grid"></i> Menu We Ultah</a></li>
                                    <li><a href={`/menu-we-website/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Website" style={{fontSize:"13pt"}}><i class="bi bi-grid"></i> Menu We Website</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-2">
                            { urlFoto === null
                                ? <img src='https://webew.id/img/default.jpg' className='mx-auto d-block' alt='foto' style={{marginTop:"3px",minWidth:'42px',maxWidth:'42px',minHeight:"42px",maxHeight:"42px",borderRadius:'50%'}}/>
                                : <img src={urlFoto} className='mx-auto d-block' alt='foto' style={{marginTop:"3px",minWidth:'42px',maxWidth:'42px',minHeight:"42px",maxHeight:"42px",borderRadius:'50%'}}/>
                            }
                        </div>
                        <div className="col-2 d-flex justify-content-center"><button className='btn btn-webew-product logout-btn mx-auto d-block' data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi' style={{width:"auto",padding:"1px 8px",marginTop:"3px", position:"relative"}} aria-label="Donasi webew.id"><i className='bi bi-coin'style={{fontSize:"20pt",color:"white"}}></i><span className="logout-text">Donasi</span></button></div>
                        <div className="col-2 d-flex justify-content-center"><button onClick={Logout} className="btn btn-webew-product logout-btn" style={{width:"auto",padding:"1px 8px",marginTop:"3px", position:"relative"}} aria-label="Produk webew.id"><i class="bi bi-power" style={{fontSize:"20pt",color:"white"}}></i><span className="logout-text">Logout</span></button></div>
                    </div>
                </div>
            </div>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU NAVBAR BOTTOM
// EKSPOR
export default MenuNavbarBot