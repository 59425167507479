// IMPORT REACT
import React,{ useState } from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// IMPORT MODAL IKLAN
import Modal from '../../Layout/Modal.jsx'
// WE KHODAM
const WeKompres = () => { // KURUNG BUKA WE KHODAM
    // MEMBUAT VARIABEL WE KOMPRES
    const [selectedOption, setSelectedOption] = useState('image') // Menyimpan pilihan (image atau pdf)
    const [displayLoader, setDisplayLoader] = useState('none')
    const [compressedImage, setCompressedImage] = useState([])
    const [compressedFile, setCompressedFile] = useState([])
    const [msg, setMsg] = useState('')
    // PROSES HANDLE IMAGE DAN PDF UPLOAD
    const handleFileUpload = async (event) => {
        const files = event.target.files
        const formData = new FormData()
        Array.from(files).forEach((file) => {
            formData.append(file.type.includes('image') ? "foto" : "file", file) // Menambahkan file sesuai tipe (gambar atau PDF)
        })
        setDisplayLoader("") // Menampilkan loader
        try {
            const isImage = files[0].type.includes('image') // Mengecek tipe file pertama untuk menentukan apakah gambar atau PDF
            const url = isImage ? "https://server13.webew.id/compressImageBE" : "https://server13.webew.id/compressPdfBE" // Menentukan URL berdasarkan tipe file
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            if (response.data) {
                if (isImage && Array.isArray(response.data.images)) {
                    // Menangani respons kompresi gambar
                    const imageUrls = response.data.images.map((imageData) =>
                        URL.createObjectURL(new Blob([new Uint8Array(atob(imageData).split("").map(c => c.charCodeAt(0)))], { type: 'image/jpeg' }))
                    )
                    setCompressedImage(imageUrls)
                } else if (!isImage && Array.isArray(response.data.pdfs)) {
                    // Menangani respons kompresi PDF
                    const pdfUrls = response.data.pdfs.map((pdfData) =>
                        URL.createObjectURL(new Blob([new Uint8Array(atob(pdfData).split("").map(c => c.charCodeAt(0)))], { type: 'application/pdf' }))
                    )
                    setCompressedFile(pdfUrls)
                }
            }
            setDisplayLoader("none") // Menghilangkan loader
        } catch (error) {
            setDisplayLoader("none") // Menghilangkan loader jika terjadi error
            if (error.response) {
                setMsg(`${error.response.data.msg}`)
            } else if (error.request) {
                setMsg("Tidak ada respons dari server, coba lagi nanti.")
            } else {
                setMsg(`Terjadi kesalahan: ${error.message}`)
            }
        }
    }    
    // PROSES DOWNLOAD FOTO DAN PDF
    const downloadCompressedFiles = (type) => {
        const files = type === 'image' ? compressedImage : compressedFile
        const extension = type === 'image' ? 'jpg' : 'pdf'
        files.forEach((file, index) => {
            const link = document.createElement("a")
            link.href = file
            link.download = `${type === 'image' ? 'compressed_image' : 'compressed_file'}_${index + 1}.${extension}`
            link.click()
        })
    }
    // PROSES UNTUK KEMBALI UPLOAD
    const handleButtonUploadLagi = () => {
        window.location.reload()
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* LOADER */}
            <div className='loader-page-bg' style={{display:displayLoader}}>
                <div className='loader-page'></div>
            </div>
            {/* TUTUP LOADER */}
            {/* MODAL IKLAN */}
            <Modal/>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>We Kompres</h2>
                                <p className='text-webew-home fw-normal'>File foto kamu atau file PDF kamu memiliki size besar? kecilkan sekarang juga dengan We Kompres, ukuran File langung mengecil</p>
                                <a href={'/'} className='btn btn-webew-product' aria-label='Kembali webew.id'><i class="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/25559969_7094814.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Vectorjuice on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* BOX CEK KHODAM */}
                <section>
                    <div className='container'>
                        <div className="row justify-content-center">
                            {/* Pilihan untuk memilih jenis file yang akan dikompres */}
                            {compressedImage.length === 0 && compressedFile.length === 0 && (
                                <div className="col-12 col-md-6 col-lg-5 col-xl-4 mb-4">
                                    <div className="box px-4 py-2">
                                        <p className="text-center" style={{ color: "red" }}>{msg}</p>
                                        <h6 className="text-center fw-normal mb-3">Upload Maksimal 10 File Foto</h6>
                                        <select className="form form-select mb-4" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                            <option value="image">Kompres Foto</option>
                                            <option value="pdf">Kompres PDF</option>
                                        </select>
                                    </div>
                                </div>
                            )}
                            {/* Form upload untuk Foto */}
                            {selectedOption === 'image' && (
                                compressedImage.length === 0 ? (
                                    <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                        <div className="box px-4 py-2">
                                            <p className="text-center" style={{ color: "red" }}>{msg}</p>
                                            <h6 className="text-center fw-normal mb-3">Silahkan Pilih Foto Untuk Di Kompres</h6>
                                            <input type="file" accept="image/*" multiple className="form form-control mb-4" onChange={handleFileUpload} style={{ backgroundImage: "linear-gradient(to top,rgb(11, 97, 255) 20%,rgb(99, 154, 255) 100%)", color: "white" }} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-11 col-lg-10 mb-4">
                                            <div className="box p-4" style={{ width: '100%' }}>
                                                <div className="row mb-4">
                                                    <div className="col-12 col-sm-6 mb-3">
                                                        <button onClick={handleButtonUploadLagi} className="btn btn-webew-product">
                                                            <i className="bi bi-arrow-left-circle-fill"></i> Upload Foto Lagi
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <button onClick={() => downloadCompressedFiles('image')} className="btn btn-webew-product">
                                                            <i className="bi bi-download"></i> Download Semua
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center">
                                                    {compressedImage.map((image, index) => (
                                                        <div className="col-6 col-sm-4 col-lg-2" key={index}>
                                                            <img src={image} className="rounded" alt={`Compressed ${index + 1}`} style={{ maxWidth: "100%", marginBottom: "10px" }} />
                                                            <p className="text-center mt-2">File Terkompres {index + 1}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                            {/* Form upload untuk PDF */}
                            {selectedOption === 'pdf' && (
                                compressedFile.length === 0 ? (
                                    <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                        <div className="box px-4 py-2">
                                            <p className="text-center" style={{ color: "red" }}>{msg}</p>
                                            <h6 className="text-center fw-normal mb-3">Upload Maksimal 10 File PDF</h6>
                                            <input type="file" accept="application/pdf" multiple className="form form-control mb-4" onChange={handleFileUpload} style={{ backgroundImage: "linear-gradient(to top, rgb(11, 97, 255) 20%, rgb(99, 154, 255) 100%)", color: "white" }}/>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-11 col-lg-10 mb-4">
                                            <div className="box p-4" style={{ width: '100%' }}>
                                                <div className="row mb-4">
                                                    <div className="col-12 col-sm-6 mb-3">
                                                        <button onClick={handleButtonUploadLagi} className="btn btn-webew-product">
                                                            <i className="bi bi-arrow-left-circle-fill"></i> Upload File Lagi
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <button onClick={() => downloadCompressedFiles('file')} className="btn btn-webew-product">
                                                            <i className="bi bi-download"></i> Download Semua
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center">
                                                    {compressedFile.map((file, index) => (
                                                        <div className="col-6 col-sm-4 col-lg-2" key={index}>
                                                            <img src='https://webew.id/img/pdf_logo.jpg' className='mx-auto d-block' style={{width: '100%',minHeight:'90px'}} alt='Logo PDF'/>
                                                            <p className="text-center mt-2">File Terkompres {index + 1}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>   
                </section>
            {/* TUTUP BOX CEK KHODAM */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_25559969.htm#page=4&query=programming&position=7&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Freepik di Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/25559969_7094814.webp' className='mx-auto d-block pt-4' style={{width: '100%',minHeight:'90px'}} alt='by Vectorjuice on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Freepik on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE KHODAM
// EKSPOR
export default WeKompres