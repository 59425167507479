// IMPORT REACT
import React from 'react'
// NAVBAR BOTTOM
const NavbarBot = () => { // KURUNG BUKA NAVBAR BOTTOM
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* NAVBAR BOTTOM */}
            <div className='navbot'>
                <div className='container fixed-bottom' style={{width:'100%',height:'auto',background:'white', padding:'10px 15px 10px 15px',borderTopLeftRadius:"30px",borderTopRightRadius:"30px",boxShadow: "0 -15px 18px 5px rgba(211, 215, 241, 0.507)"}}>
                    <div className='row'>
                        <div className='col-4'><a className='btn btn-webew-product'  href={'/'} aria-label='Produk webew.id' style={{padding:'4px 0 4px 0',marginTop:'3px'}}><i class='bi bi-house-up' style={{color:'white'}}></i></a></div>
                        <div className='col-4'>
                            <div class='dropdown-center'>
                                <button class='btn btn-webew-product' type='button' data-bs-toggle='dropdown' aria-expanded='false' style={{padding:'7px 0 7px 0'}}><i class='bi bi-grid-fill' style={{color:'white'}}></i></button>
                                <ul class='dropdown-menu mb-3'>
                                    <li><a className='dropdown-item' href={'/'} aria-label='Home' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> Home</a></li>
                                    <li><a className='dropdown-item' href={'/we-aqiqah'} aria-label='We Aqiqah' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> We Aqiqah</a></li>
                                    <li><a className='dropdown-item' href={'/we-cv'} aria-label='We CV' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> We CV</a></li>
                                    <li><a className='dropdown-item' href={'/we-khodam'} aria-label='We Khodam' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> We Khodam</a></li>
                                    <li><a className='dropdown-item' href={'/we-kompres'} aria-label='We Kompres' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> We Kompres</a></li>
                                    <li><a className='dropdown-item' href={'/we-konversi'} aria-label='We Konversi' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> We Konversi</a></li>
                                    <li><a className='dropdown-item' href={'/we-motivasi'} aria-label='We Motivasi' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> We Motivasi</a></li>
                                    <li><a className='dropdown-item' href={'/we-nabung'} aria-label='We Nabung' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> We Nabung</a></li>
                                    <li><a className='dropdown-item' href={'/we-pernikahan'} aria-label='We Pernikahan' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> We Pernikahan</a></li>
                                    <li><a className='dropdown-item' href={'/we-ultah'} aria-label='We Ultah' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> We Ultah</a></li>
                                    <li><a className='dropdown-item' href={'/we-website'} aria-label='We Website' style={{fontSize:'13pt'}}><i class="bi bi-grid"></i> We Website</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-4'><a className='btn btn-webew-product' href={'https://webew.id/login/dashboard'} aria-label='Produk webew.id' style={{padding:'4px 0 4px 0',marginTop:'3px'}}><i class='bi bi-box-arrow-in-right' style={{color:'white'}}></i></a></div>
                    </div>
                </div>
            </div>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP NAVBAR BOTTOM
// EKSPOR
export default NavbarBot