// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT USE PARAMS
import { useParams } from "react-router-dom"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate } from "react-router-dom"
// MENU SIDE BAR
const MenuSideBar = () => { // KURUNG BUKA MENU SIDE BAR
    // MEMBUAT VARIABEL URL FOTO
    const [urlFoto, setUrlFoto] = useState("")
    // MEMBUAT VARIABEL PARAMS BY PANGGILAN
    const { panggilanParams } = useParams();
     // MEMBUAT VARIABEL PANGGILAN
     const [panggilan, setPanggilan] = useState('')
     // MEMBUAT VARIABEL TOKEN
     const [, setToken] = useState('')
     // MEMBUAT VARIABEL EXPIRE TOKEN
     const [expire, setExpire] = useState('')
     // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
     const navigate = useNavigate()
     // USE EFFECT
     useEffect(()=>{
         refreshToken()
         selectWeUsers()
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [])
    //  REFRESH TOKEN
     const refreshToken = async() => {
         try {
             const response = await axios.get('https://server13.webew.id/tokenBE')
             setToken(response.data.accessToken)
             const decoded = jwt_decode(response.data.accessToken)
             setPanggilan(decoded.panggilan)
             setExpire(decoded.exp)
         } catch (error) {
             if(error.response){
                 navigate("/login/dashboard", { replace: true })
             }   
         }
     }
     const axiosJWT = axios.create()
     axiosJWT.interceptors.request.use(async(config) => {
         const currentDate = new Date()
         if(expire * 1000 < currentDate.getTime()){
             const response = await axios.get('https://server13.webew.id/tokenBE')
             config.headers.Authorization = `Bearer ${response.data.accessToken}`
             setToken(response.data.accessToken)
             const decoded = jwt_decode(response.data.accessToken)
             setPanggilan(decoded.panggilan)
             setExpire(decoded.exp)
         }
         return config
     }, (error)=>{
         return Promise.reject(error)
     })
     // LOGOUT
    const Logout = async() =>{
        try {
            await axios.delete('https://server13.webew.id/logoutBE')
            navigate("/login/dashboard", { replace: true })
        } catch (error) {
            console.log(error)
        }
    }
    // MENAMPILKAN WE USERS BY PARAMS PANGGILAN
    const selectWeUsers = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeUsersBE/${panggilanParams}`)
            setUrlFoto(response.data.urlFoto)
        }catch(error){
            if(error.response){
                console.log(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* SIDE BAR */}
            <nav className='navbar-expand-sm'>
                <div className='dropdown ml-2'></div>
                <div className='collapse navbar-collapse' id='sidebar'>
                    <ul className='navbar-nav' id='accordionSidebar'>
                        <div className=' bg-navbar py-4 px-2 mt-1'>
                            <div className='navbar-brand d-flex align-items-center justify-content-center'>
                                <div className='d-flex py-2' role='search' style={{backgroundColor:"white",borderRadius:"50px"}}>
                                    <button type='button' className='btn btn-webew-product mx-2' data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi' style={{padding:"0 7.5px 0 7.5px"}}><p className='bi bi-coin'style={{color:'white',fontSize:'20px',margin:'0',fontWeight:'600'}}></p></button>
                                    { urlFoto === null
                                        ? <img src='https://webew.id/img/default.jpg' className='mx-auto d-block' alt='foto' style={{minWidth:'35px',maxWidth:'35px',borderRadius:'50%'}}/>
                                        : <img src={urlFoto} className='mx-auto d-block' alt='foto' style={{minWidth:'35px',maxWidth:'35px',borderRadius:'50%'}}/>
                                    }
                                    <button onClick={Logout} className='btn btn-logout mx-2'><i className='bi bi-power' style={{color:'white',fontSize:'22px'}}></i></button>
                                </div>
                            </div>
                            <div className='sidebar-heading'>Menu Webew</div>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/dashboard/${panggilan}`}><i className='bi bi-columns-gap'></i><span>Dashboard</span></a>
                            </li>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/menu-we-aqiqah/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Aqiqah</span></a>
                            </li>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/menu-we-cv/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We CV</span></a>
                            </li>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/menu-we-khodam/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Khodam</span></a>
                            </li>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/menu-we-kompres/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Kompres</span></a>
                            </li>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/menu-we-konversi/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Konversi</span></a>
                            </li>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/menu-we-motivasi/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Motivasi</span></a>
                            </li>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/menu-we-nabung/${panggilan}/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Nabung</span></a>
                            </li>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/menu-we-pernikahan/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Pernikahan</span></a>
                            </li>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/menu-we-ultah/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Ultah</span></a>
                            </li>
                            <li className='nav-item nav-itemm'>
                                <a className='nav-link nav-linkk' href={`/menu-we-website/${panggilan}`}><i className='bi bi-columns-gap'></i><span>We Website</span></a>
                            </li>
                        </div>
                    </ul>
                </div>
            </nav>
            {/* TUTUP SIDE BAR */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU SIDE BAR
// EKSPOR
export default MenuSideBar