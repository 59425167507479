// IMPORT REACT
import React from 'react'
// IMPORT LINK
import { Link } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// WE CV
const WeCv = () => { // KURUNG BUKA WE CV
    // PROSES LOOP JENIS CV
    const jenisWeCv = [
        { title: 'CV 1', imgSrc: 'https://webew.id/img/bikinCV/cvg1.webp', alt: 'Buat CV 1' },
        { title: 'CV 2', imgSrc: 'https://webew.id/img/bikinCV/cvg2.webp', alt: 'Buat CV 2' },
        { title: 'CV 3', imgSrc: 'https://webew.id/img/bikinCV/cvg3.webp', alt: 'Buat CV 3' },
        { title: 'CV 4', imgSrc: 'https://webew.id/img/bikinCV/cvg4.webp', alt: 'Buat CV 4' },
    ]
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>We CV</h2>
                                <p className='text-webew-home fw-normal'>Buat CV online dengan mudah dan cepat disini, tinggal pilih desain, isi form data dengan benar, lalu download Cv nya</p>
                                 <a href={'/'} className='btn btn-webew-product' aria-label='Kembali webew.id'><i class="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/6976378_4565.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Freepik on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* JENIS WE CV */}
                <section>
                    <div className='container'>
                        <div className='row'>
                            {jenisWeCv.map((data, index) => (
                                <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3'>
                                    <div className='card mb-4 pt-3 pb-2 px-2' style={{ width: '100%'}}>
                                        <h5 className='card-title fw-bold text-center mt-2'>{data.title}</h5>
                                        <a href={data.imgSrc} aria-label='cv'>
                                            <img src={data.imgSrc}className='mx-auto d-block mt-2'style={{ maxHeight: '250px' }}alt={data.alt}/>
                                        </a>
                                        <div className='card-body'>
                                            <a href={'https://webew.id/login/menu-we-cv'} className='btn btn-webew-product my-1' aria-label='Produk webew.id'>Buat</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            {/* TUTUP JENIS WE CV */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/recruit-agent-analyzing-candidates_6976378.htm#page=4&position=14&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img src='https://webew.id/img/credit/6976378_4565.webp' className='mx-auto d-block'  style={{width: '100%',minHeight:'120px'}} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE CV
// EKSPOR
export default WeCv